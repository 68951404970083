import React from 'react';
import './Benefits.css';

const Benefits = () => {
  const benefits = [
    {
      title: 'Steady Lead Flow',
      description: 'Generate consistent pipeline of qualified appointments from decision-makers'
    },
    {
      title: 'Focus on Closing',
      description: 'Free your sales team to focus on what they do best - closing deals'
    },
    {
      title: 'Pay Per Result',
      description: 'Only pay for qualified appointments - no retainers or hidden fees'
    }
  ];

  return (
    <section className="benefits">
      <div className="benefits-container">
        <h2>Why Choose Our Solution?</h2>
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <div key={index} className="benefit-card">
              <div className="benefit-content">
                <div className="checkmark">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="white"/>
                  </svg>
                </div>
                <div className="benefit-text">
                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="benefits-cta">
          <button className="primary-cta">Book Your Strategy Call</button>
        </div>
      </div>
    </section>
  );
};

export default Benefits; 