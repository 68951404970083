import React from 'react';
import './Solution.css';

const Solution = () => {
  const steps = [
    {
      number: '01',
      title: 'Identify your ICP',
      description: 'We help you define and target your ideal customer profile',
      icon: '🎯'
    },
    {
      number: '02',
      title: 'Generate Prospect List',
      description: 'Build a targeted list of prospects matching your ICP',
      icon: '📋'
    },
    {
      number: '03',
      title: 'Launch Campaign',
      description: 'Execute split-tested outreach campaigns by our copywriting team',
      icon: '🚀'
    },
    {
      number: '04',
      title: 'Monitor & Respond',
      description: 'Our SDR team ensures quick responses to interested leads',
      icon: '📊'
    },
    {
      number: '05',
      title: 'Qualify Leads',
      description: 'Prepare interested leads for appointments with your team',
      icon: '✅'
    },
    {
      number: '06',
      title: 'Close Deals',
      description: 'Your team focuses on what they do best - closing deals',
      icon: '🤝'
    }
  ];

  return (
    <section className="solution" id="solution">
      <div className="solution-container">
        <h2>Our Step-by-Step Approach</h2>
        <p className="solution-subtitle">PPL B2B outreach campaign customized for your ICP</p>
        
        <div className="steps-grid">
          {steps.map((step, index) => (
            <div key={index} className="step-card">
              <div className="step-icon">{step.icon}</div>
              <div className="step-content">
                <div className="step-number">{step.number}</div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
              {index !== steps.length - 1 && <div className="step-connector" />}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solution; 