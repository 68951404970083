import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('faq'); // 'faq' or 'features'

  const faqs = [
    {
      question: 'How quickly will I start seeing results?',
      icon: '⚡',
      points: [
        { icon: '🔄', text: 'System setup within 48 hours of onboarding' },
        { icon: '📅', text: 'First qualified appointments within 2 weeks' },
        { icon: '📈', text: 'Consistent meeting flow by week 3-4' }
      ],
      color: '#4CAF50'
    },
    {
      question: 'What kind of companies will I be connected with?',
      icon: '🎯',
      points: [
        { icon: '💼', text: 'Fast-growing companies with active hiring budgets' },
        { icon: '📋', text: 'Multiple open positions to fill' },
        { icon: '🤝', text: 'Decision makers ready to partner with agencies' }
      ],
      color: '#2196F3'
    },
    {
      question: 'How will this transform my recruitment business?',
      icon: '🚀',
      points: [
        { icon: '📊', text: 'Eliminate cold calling and manual outreach' },
        { icon: '🗓️', text: 'Pre-qualified meetings on your calendar' },
        { icon: '✨', text: 'Focus solely on closing deals' }
      ],
      color: '#9C27B0'
    },
    {
      question: 'What makes your leads different from others?',
      icon: '💎',
      points: [
        { icon: '🔍', text: 'Proprietary data analysis for hiring signals' },
        { icon: '✅', text: 'Thorough qualification process' },
        { icon: '📱', text: 'Multi-channel verification' }
      ],
      color: '#FF9800'
    },
    {
      question: 'How do you ensure maximum value for my investment?',
      icon: '💰',
      points: [
        { icon: '🎯', text: 'Pay only for qualified appointments' },
        { icon: '📋', text: 'Detailed lead qualification criteria' },
        { icon: '🔒', text: 'Money-back guarantee on unqualified leads' }
      ],
      color: '#E91E63'
    }
  ];

  const features = [
    {
      title: 'Campaign Setup',
      description: 'Full-service campaign launch',
      items: [
        { text: 'ICP & Market Research', icon: '🎯' },
        { text: 'Custom Campaign Strategy', icon: '📊' },
        { text: 'Messaging Development', icon: '✍️' },
        { text: 'A/B Testing Framework', icon: '🔄' }
      ],
      color: '#4CAF50'
    },
    {
      title: 'Lead Generation',
      description: 'Consistent lead flow',
      items: [
        { text: 'Targeted List Building', icon: '📋' },
        { text: 'Multi-Channel Outreach', icon: '📱' },
        { text: 'Real-time Lead Tracking', icon: '📈' },
        { text: 'Response Management', icon: '💬' }
      ],
      color: '#2196F3'
    },
    {
      title: 'Ongoing Support',
      description: 'Dedicated success team',
      items: [
        { text: 'Account Management', icon: '👤' },
        { text: '24/7 Campaign Monitoring', icon: '🔍' },
        { text: 'Weekly Performance Reports', icon: '📑' },
        { text: 'Strategy Optimization', icon: '⚡' }
      ],
      color: '#9C27B0'
    }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq" id="faq">
      <div className="faq-container">
        <div className="faq-header fade-in">
          <span className="faq-badge pulse">Results & Transformation</span>
          <h2>Your Success Story Starts Here</h2>
          <p>Discover how we are transforming recruitment businesses with qualified leads</p>
        </div>

        <div className="content-tabs">
          <button 
            className={`tab-button ${activeTab === 'faq' ? 'active' : ''}`}
            onClick={() => setActiveTab('faq')}
          >
            Common Questions
          </button>
          <button 
            className={`tab-button ${activeTab === 'features' ? 'active' : ''}`}
            onClick={() => setActiveTab('features')}
          >
            What's Included
          </button>
        </div>

        {activeTab === 'faq' ? (
          <div className="faq-grid">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className={`faq-card ${activeIndex === index ? 'active' : ''}`}
                style={{ '--faq-color': faq.color }}
              >
                <button 
                  className="faq-question"
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="question-content">
                    <div className="faq-icon-wrapper">
                      <span className="faq-icon">{faq.icon}</span>
                    </div>
                    <span className="question-text">{faq.question}</span>
                  </div>
                  <span className="arrow">{activeIndex === index ? '−' : '+'}</span>
                </button>
                
                <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                  <div className="faq-points">
                    {faq.points.map((point, pointIndex) => (
                      <div key={pointIndex} className="faq-point">
                        <span className="point-icon">{point.icon}</span>
                        <span className="point-text">{point.text}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="features-grid">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className="feature-card"
                style={{ '--feature-color': feature.color }}
              >
                <div className="feature-header">
                  <div className="feature-icon-large">{feature.items[0].icon}</div>
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
                
                <div className="feature-list">
                  {feature.items.map((item, idx) => (
                    <div key={idx} className="feature-item">
                      <div className="feature-item-icon">
                        {item.icon}
                      </div>
                      <span className="feature-item-text">{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="section-cta">
          <div className="cta-wrapper">
            <div className="cta-content">
              <h3>Ready to Transform Your Lead Generation?</h3>
              <p>Book your free strategy call today and discover how we can help you:</p>
              <div className="cta-benefits">
                <div className="cta-benefit">
                  <span className="benefit-check">✓</span>
                  <span>Generate qualified meetings with companies actively hiring</span>
                </div>
                <div className="cta-benefit">
                  <span className="benefit-check">✓</span>
                  <span>Only pay for meetings that match your criteria</span>
                </div>
                <div className="cta-benefit">
                  <span className="benefit-check">✓</span>
                  <span>Start seeing results within weeks, not months</span>
                </div>
              </div>
              <button className="primary-cta">
                Book Your Free Strategy Call
                <span className="arrow-icon">→</span>
              </button>
              <p className="cta-note">Limited spots available this month</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ; 