import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-background">
        <div className="animated-shapes">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="shape" />
          ))}
        </div>
      </div>
      <div className="hero-content">
        <div className="hero-text">
          <h1>
            <span className="gradient-text">Fill your Calendar,</span>
            <br />
            Clear your mind
          </h1>
          <h2>Transform your B2B outreach with data-driven campaigns</h2>
          <p className="hero-subtitle">
            Stop chasing leads. Start closing deals. Get qualified appointments delivered to your calendar.
          </p>
        </div>
        <div className="hero-cta">
          <button className="primary-cta">Book Your Strategy Call</button>
          <button className="cta-button secondary">Watch Demo</button>
        </div>
        <div className="hero-stats">
          <div className="stat">
            <span className="stat-number">Free</span>
          </div>
          <div className="stat">
            <span className="stat-number">Strategy</span>
          </div>
          <div className="stat">
            <span className="stat-number">Call</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 