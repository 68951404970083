import React from 'react';
import './RecruitmentFocus.css';

const RecruitmentFocus = () => {
  const focusAreas = [
    {
      icon: '🎯',
      title: 'Hiring Intent Data',
      description: 'We identify companies showing active hiring signals through job postings, growth indicators, and funding rounds',
      color: '#4CAF50'
    },
    {
      icon: '👥',
      title: 'Decision Maker Access',
      description: 'Direct connections with HR leaders, hiring managers, and department heads who make hiring decisions',
      color: '#2196F3'
    },
    {
      icon: '📈',
      title: 'Growth Companies',
      description: 'Focus on fast-growing companies with consistent hiring needs and long-term partnership potential',
      color: '#9C27B0'
    },
    {
      icon: '🤝',
      title: 'Qualified Meetings',
      description: 'Connect with companies that have immediate hiring needs and budget allocated for recruitment',
      color: '#FF9800'
    }
  ];

  return (
    <section className="recruitment-focus" id="recruitment">
      <div className="recruitment-container">
        <div className="focus-header fade-in">
          <span className="focus-badge pulse">Recruitment Focus</span>
          <h2>B2B Lead Generation for Recruiters</h2>
          <p>Helping recruitment agencies connect with companies actively looking to hire</p>
        </div>

        <div className="focus-grid">
          {focusAreas.map((area, index) => (
            <div 
              key={index} 
              className="focus-card"
              style={{ '--card-color': area.color }}
            >
              <div className="focus-card-content">
                <div className="focus-icon-wrapper">
                  <span className="focus-icon">{area.icon}</span>
                </div>
                <div className="focus-text">
                  <h3>{area.title}</h3>
                  <p>{area.description}</p>
                </div>
              </div>
              <div className="focus-card-overlay"></div>
            </div>
          ))}
        </div>

        <div className="focus-cta">
          <button className="primary-cta">Start Generating Leads</button>
        </div>
      </div>
    </section>
  );
};

export default RecruitmentFocus; 