import './App.css';
import './components/shared.css';
import Hero from './components/Hero';
import Problems from './components/Problems';
import RecruitmentFocus from './components/RecruitmentFocus';
import Solution from './components/Solution';
import Benefits from './components/Benefits';
import Roadmap from './components/Roadmap';
import About from './components/About';
import FAQ from './components/FAQ';
import ScrollAnimation from './components/ScrollAnimation';
import NavBar from './components/NavBar';
import ScrollProgress from './components/ScrollProgress';
import BackToTop from './components/BackToTop';
import LoadingScreen from './components/LoadingScreen';
import Footer from './components/Footer';
import { useState, useEffect } from 'react';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="App">
      <ScrollProgress />
      <NavBar />
      <Hero />
      <ScrollAnimation>
        <Problems />
      </ScrollAnimation>
      <ScrollAnimation>
        <RecruitmentFocus />
      </ScrollAnimation>
      <ScrollAnimation>
        <Solution />
      </ScrollAnimation>
      <ScrollAnimation>
        <Benefits />
      </ScrollAnimation>
      <ScrollAnimation>
        <Roadmap />
      </ScrollAnimation>
      <ScrollAnimation>
        <About />
      </ScrollAnimation>
      <ScrollAnimation>
        <FAQ />
      </ScrollAnimation>
      <BackToTop />
      <Footer />
    </div>
  );
}

export default App;
