import React from 'react';
import './About.css';

const About = () => {
  const metrics = [
    {
      number: "30+",
      label: "Years Experience",
      description: "Combined business development expertise"
    },
    {
      number: "50x",
      label: "ROI",
      description: "Maximum return on investment achieved"
    },
    {
      number: "3",
      label: "Major Hubs",
      description: "Canadian offices serving global clients"
    }
  ];

  const locations = ["Toronto", "Vancouver", "Montreal"];

  return (
    <section className="about" id="about">
      <div className="about-container">
        <div className="about-header">
          <h2>Why Choose Us</h2>
          <p className="about-tagline">Canadian Excellence, Global Reach</p>
        </div>

        <div className="metrics-grid">
          {metrics.map((metric, index) => (
            <div key={index} className="metric-card">
              <div className="metric-number">{metric.number}</div>
              <h3 className="metric-label">{metric.label}</h3>
              <p className="metric-description">{metric.description}</p>
            </div>
          ))}
        </div>

        <div className="about-content">
          <div className="content-left">
            <h3>Canadian Team, Global Impact</h3>
            <p>
              Based in Canada but serving clients globally, we combine local expertise 
              with international reach. Our team brings over 30 years of combined business 
              development experience, delivering exceptional results with ROI ranging from 
              10x to 50x. We maintain the high standards and reliability that Canadian 
              companies are known for while helping businesses scale across borders.
            </p>
            <div className="expertise-grid">
              <div className="expertise-item">
                <span className="expertise-icon">🌎</span>
                <div>
                  <h4>Global Reach</h4>
                  <p>Serving clients across multiple time zones with local expertise</p>
                </div>
              </div>
              <div className="expertise-item">
                <span className="expertise-icon">📈</span>
                <div>
                  <h4>Proven Track Record</h4>
                  <p>Consistent delivery of qualified leads and measurable ROI</p>
                </div>
              </div>
            </div>
            <div className="locations-bar">
              <span className="location-label">Canadian Offices:</span>
              {locations.map((city, index) => (
                <span key={index} className="location-chip">{city}</span>
              ))}
            </div>
          </div>
          
          <div className="content-right">
            <div className="feature-item">
              <span className="feature-icon">🔒</span>
              <div>
                <h4>Global Data Compliance</h4>
                <p>CASL, GDPR, and international data security standards</p>
              </div>
            </div>
            <div className="feature-item">
              <span className="feature-icon">🤝</span>
              <div>
                <h4>Strategic Partnerships</h4>
                <p>Long-term relationships built on trust and results</p>
              </div>
            </div>
            <div className="feature-item">
              <span className="feature-icon">⏰</span>
              <div>
                <h4>International Support</h4>
                <p>Dedicated support across multiple time zones</p>
              </div>
            </div>
          </div>
        </div>

        <div className="about-cta">
          <button className="primary-cta">Book Your Strategy Call</button>
        </div>
      </div>
    </section>
  );
};

export default About;