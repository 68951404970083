import React, { useState, useEffect } from 'react';
import './NavBar.css';

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="nav-content">
        <div className="logo" onClick={scrollToTop} style={{ cursor: 'pointer' }}>
          <img src="/logo.png" alt="The Humble Company Logo" className="nav-logo" />
          <span>The Humble Company</span>
        </div>
        <button 
          className={`mobile-menu-btn ${isMenuOpen ? 'open' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <a href="#problems">Challenges</a>
          <a href="#solution">Solutions</a>
          <a href="#roadmap">Process</a>
          <a href="#about">Why Us</a>
          <a href="#faq">FAQ</a>
          <button className="primary-cta">Book Your Strategy Call</button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar; 