import React, { useState, useEffect } from 'react';
import './Roadmap.css';

const Roadmap = () => {
  const [activePhase, setActivePhase] = useState(1);
  const [isVisible, setIsVisible] = useState(false);

  // Add intersection observer to trigger animation when component is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const element = document.querySelector('.roadmap');
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  const phases = [
    {
      id: 1,
      week: 'Week 1',
      title: 'Discovery & Setup',
      description: 'Building the foundation for your success',
      color: '#4361EE',
      items: [
        { icon: '🎯', text: 'ICP Analysis', progress: 30, detail: 'Identifying your perfect customer profile' },
        { icon: '📊', text: 'Market Research', progress: 25, detail: 'Deep dive into market opportunities' },
        { icon: '📝', text: 'Campaign Strategy', progress: 35, detail: 'Crafting your unique approach' },
        { icon: '📋', text: 'Initial List Building', progress: 20, detail: 'Creating your prospect database' }
      ]
    },
    {
      id: 2,
      week: 'Week 2',
      title: 'Campaign Preparation',
      description: 'Crafting your perfect outreach strategy',
      color: '#4361EE',
      items: [
        { icon: '✍️', text: 'Copy Development', progress: 65, detail: 'Creating compelling copy for your outreach' },
        { icon: '🔄', text: 'A/B Test Setup', progress: 70, detail: 'Setting up A/B tests to optimize your strategy' },
        { icon: '⚙️', text: 'Automation Configuration', progress: 75, detail: 'Configuring automation tools for efficiency' },
        { icon: '✅', text: 'Quality Checks', progress: 60, detail: 'Ensuring high-quality content and delivery' }
      ]
    },
    {
      id: 3,
      week: 'Week 3',
      title: 'Launch & Optimize',
      description: 'Taking your campaign to market',
      color: '#4361EE',
      items: [
        { icon: '🚀', text: 'Campaign Launch', progress: 100, detail: 'Launching your campaign and getting initial feedback' },
        { icon: '📈', text: 'Response Monitoring', progress: 95, detail: 'Monitoring responses and adjusting strategy' },
        { icon: '🎯', text: 'Lead Qualification', progress: 90, detail: 'Qualifying leads and improving conversion rates' },
        { icon: '📊', text: 'Performance Analysis', progress: 85, detail: 'Analyzing campaign performance and making optimizations' }
      ]
    }
  ];

  const handlePhaseClick = (phaseId) => {
    setActivePhase(phaseId);
    // Add smooth scroll to the phase content
    const element = document.querySelector('.phases-container');
    element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  return (
    <section className="roadmap" id="roadmap">
      <div className="roadmap-container">
        <div className="roadmap-header fade-in">
          <span className="phase-badge pulse">Implementation Process</span>
          <h2>{phases[activePhase - 1].title}</h2>
          <p className="roadmap-subtitle">{phases[activePhase - 1].description}</p>
        </div>

        <div className="timeline-nav slide-in">
          {phases.map((phase) => (
            <button
              key={phase.id}
              className={`timeline-nav-item ${activePhase === phase.id ? 'active' : ''}`}
              onClick={() => handlePhaseClick(phase.id)}
              style={{ '--phase-color': phase.color }}
            >
              <span className="week-marker">{phase.week}</span>
              <span className="nav-title">{phase.title}</span>
              <div className="progress-indicator"></div>
            </button>
          ))}
        </div>

        <div className="phases-container fade-in">
          {phases.map((phase) => (
            <div
              key={phase.id}
              className={`phase-block ${activePhase === phase.id ? 'active' : ''}`}
              style={{ '--phase-color': phase.color }}
            >
              <div className="phase-content">
                <div className="phase-items">
                  {phase.items.map((item, index) => (
                    <div 
                      key={index} 
                      className="phase-item"
                      style={{ '--animation-delay': `${index * 0.1}s` }}
                    >
                      <div className="item-header">
                        <div className="item-icon-wrapper">
                          <span className="item-icon">{item.icon}</span>
                        </div>
                        <div className="item-text-container">
                          <span className="item-text">{item.text}</span>
                          <span className="item-detail">{item.detail}</span>
                        </div>
                      </div>
                      <div className="progress-wrapper">
                        <div 
                          className="progress-bar" 
                          style={{ width: `${item.progress}%` }}
                        >
                          <span className="progress-label">{item.progress}%</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Roadmap;