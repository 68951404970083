import React, { useState } from 'react';
import './Problems.css';

const Problems = () => {
  const [activeProblem, setActiveProblem] = useState(1);

  const problems = [
    {
      id: 1,
      icon: '🎯',
      title: 'Bad Targeting',
      description: 'Wasting resources on leads that don\'t convert',
      stats: '67% of B2B companies',
      impact: 'Decreased ROI',
      color: '#FF6B6B',
      solution: {
        title: 'Smart Lead Qualification',
        description: 'Our AI-powered system ensures you only pursue the most promising leads',
        benefits: [
          'Predictive lead scoring',
          'Intent data analysis',
          'Behavioral tracking'
        ]
      }
    },
    {
      id: 2,
      icon: '📈',
      title: 'Empty Pipelines',
      description: 'Inconsistent lead flow affecting your revenue',
      stats: '43% revenue loss',
      impact: 'Unstable Growth',
      color: '#4ECDC4',
      solution: {
        title: 'Continuous Pipeline Generation',
        description: 'Never worry about pipeline gaps with our automated system',
        benefits: [
          'Multi-channel outreach',
          '24/7 lead generation',
          'Real-time optimization'
        ]
      }
    },
    {
      id: 3,
      icon: '💰',
      title: 'Expensive Retainers',
      description: 'Paying high monthly fees with uncertain results',
      stats: '$5k+ monthly waste',
      impact: 'Budget Drain',
      color: '#FFD93D',
      solution: {
        title: 'Performance-Based Model',
        description: 'Only pay for results with our success-based pricing',
        benefits: [
          'No retainer fees',
          'Pay per meeting',
          'Transparent pricing'
        ]
      }
    },
    {
      id: 4,
      icon: '⏰',
      title: 'No Time to Prospect',
      description: 'Your sales team spending time prospecting instead of closing',
      stats: '5hrs/day wasted',
      impact: 'Lost Opportunities',
      color: '#6C5CE7',
      solution: {
        title: 'Automated Prospecting',
        description: 'Let your team focus on closing while we handle prospecting',
        benefits: [
          'Automated outreach',
          'Meeting scheduling',
          'Follow-up management'
        ]
      }
    }
  ];

  return (
    <section className="problems-section" id="problems">
      <div className="problems-container">
        <div className="problems-header">
          <div className="header-tag">Common Challenges</div>
          <h2>Solving Your B2B Sales Challenges</h2>
          <p>Transform your obstacles into opportunities</p>
        </div>

        <div className="problems-content">
          <div className="problems-nav">
            {problems.map((problem) => (
              <button
                key={problem.id}
                className={`nav-item ${activeProblem === problem.id ? 'active' : ''}`}
                onClick={() => setActiveProblem(problem.id)}
                style={{'--highlight-color': problem.color}}
              >
                <span className="problem-icon">{problem.icon}</span>
                <div className="nav-text">
                  <h3>{problem.title}</h3>
                  <p>{problem.description}</p>
                </div>
                <div className="nav-metrics">
                  <span className="metric">{problem.stats}</span>
                  <span className="impact">{problem.impact}</span>
                </div>
              </button>
            ))}
          </div>

          <div className="solution-display">
            {problems.map((problem) => (
              <div
                key={problem.id}
                className={`solution-content ${activeProblem === problem.id ? 'active' : ''}`}
                style={{'--solution-color': problem.color}}
              >
                <div className="solution-header">
                  <span className="solution-tag">Our Solution</span>
                  <h3>{problem.solution.title}</h3>
                  <p>{problem.solution.description}</p>
                </div>

                <div className="benefits-grid">
                  {problem.solution.benefits.map((benefit, index) => (
                    <div key={index} className="benefit-card">
                      <svg className="check-icon" viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
                      </svg>
                      <span>{benefit}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="action-banner">
          <div className="banner-content">
            <h3>What You'll Get During Your Free Strategy Call</h3>
            <p>30-minute discovery session with our lead generation experts</p>
            
            <div className="strategy-call-benefits">
              <div className="benefit-item">
                <span className="benefit-icon">🔍</span>
                <div className="benefit-details">
                  <h4>Campaign Audit</h4>
                  <p>We'll analyze your current outreach strategy and identify improvement opportunities</p>
                </div>
              </div>
              <div className="benefit-item">
                <span className="benefit-icon">🎯</span>
                <div className="benefit-details">
                  <h4>Market Analysis</h4>
                  <p>Get insights into what's working in recruitment lead generation right now</p>
                </div>
              </div>
              <div className="benefit-item">
                <span className="benefit-icon">💰</span>
                <div className="benefit-details">
                  <h4>Pricing Discussion</h4>
                  <p>Learn about our pay-per-meeting model and how it minimizes your risk</p>
                </div>
              </div>
              <div className="benefit-item">
                <span className="benefit-icon">📈</span>
                <div className="benefit-details">
                  <h4>Growth Roadmap</h4>
                  <p>Get a clear action plan to scale your recruitment business with qualified leads</p>
                </div>
              </div>
            </div>

            <button className="primary-cta">
              Book Your Strategy Call
              <svg className="arrow-icon" viewBox="0 0 24 24">
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Problems; 