import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-brand">
            <img src="/logo.png" alt="The Humble Company Logo" className="footer-logo" />
            <h3>The Humble Company</h3>
          </div>
          
          <div className="social-links">
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <span className="social-icon">LinkedIn</span>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <span className="social-icon">Twitter</span>
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <span className="social-icon">YouTube</span>
            </a>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p>&copy; {currentYear} The Humble Company</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 